.company
    border-radius: 8px

.text-blocks
    --pad-sides: 100px
    --pad-top: 48px
    --gap: 32px

    background: #ECE7E5
    padding: var(--pad-top) var(--pad-sides)

    .texts
        display: flex
        flex-direction: column
        gap: var(--gap)

        .text

            h3
                margin-bottom: var(--gap)
                font-weight: 500
                color: var(--green-color)

            ul
                margin: 0

@media (max-width: 1723.98px)
    .text-blocks
        --pad-sides: 60px    

@media (max-width: 1399.98px)
    .text-blocks
        --pad-sides: 40px
        --pad-top: 40px

@media (max-width: 1199.98px)
    .text-blocks
        --gap: 24px

@media (max-width: 991.98px)
    .text-blocks
        --pad-sides: 24px
        --pad-top: 24px
        --gap: 16px