.first-screen
    --height: 450px
    --offset-top: 125px
    position: relative
    margin-top: calc(var(--offset-top) * -1)
    padding-top: var(--offset-top)
    height: 100vh
    min-height: calc(var(--height) + var(--offset-top))

    .bg
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%
        background: url('/imgs/main-bg.jpg') center no-repeat
        background-size: cover

    .container
        height: 100%

.first-screen-wrapper
    display: flex
    height: 100%
    align-items: center

.main-slider
    padding-bottom: 33px

    .service-slide
        max-width: 744px
        color: white

        .service-name
            font-family: var(--gilroy)
            font-size: 64px
            font-weight: bold
            line-height: normal
            margin-bottom: 28px

        .service-text
            margin-bottom: 28px

@media (max-width: 1399.98px)
    .main-slider
        .service-slide
            .service-name
                font-size: 56px

@media (max-width: 1199.98px)
    .first-screen
        height: auto
        display: flex
        align-items: center

    .main-slider
        .service-slide
            .service-name
                font-size: 40px

@media (max-width: 991.98px)
    .first-screen
        --offset-top: 110px
        --height: 400px

    .main-slider
        .service-slide
            .service-name
                font-size: 32px
                margin-bottom: 24px

            .service-text
                margin-bottom: 24px

@media (max-width: 767.98px)
    .first-screen
        --height: 300px
        
    .first-screen-wrapper
        padding: 40px 0

    .main-slider
        padding-bottom: 0

        .service-slide
            .service-name
                font-size: 24px

@media (max-width: 575.98px)
    .first-screen
        --offset-top: 100px

    .first-screen-wrapper
        .btns
            flex-wrap: wrap
            .btns-link
                width: 100%
                order: 1
    
    .main-slider
        .service-slide
            .service-name
                font-size: 20px