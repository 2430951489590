.articles-slider
    margin-top: calc(var(--mt) - 15px)

    .article-slide
        display: flex
        flex-direction: column
        gap: 24px

@media (max-width: 767.98px)
    .articles-slider
        margin-top: var(--mt)