.employee-modal
    --pad: 32px
    --name-size: 48px
    --img-width: 400px

    .modal-dialog
        max-width: 911px

    .modal-content
        border-radius: 8px
        background: #F1EBD9

    .modal-body
        padding: var(--pad)
        display: flex
        gap: 48px
        align-items: center
        background: inherit

        .close-btn
            position: absolute
            top: 8px
            right: 8px

        .img
            width: var(--img-width)
            overflow: hidden
            border-radius: 8px

            img
                width: 100%
                display: block

        .text
            flex: 1
            display: flex
            flex-direction: column
            gap: 24px

            .name
                color: #909C86
                font-family: var(--gilroy)
                font-size: var(--name-size)
                font-weight: bold
                line-height: normal

@media (max-width: 1199.98px)
    .employee-modal
        --pad: 24px
        --name-size: 40px

        .modal-body
            gap: 32px

            .close-btn
                top: 0
                right: 0

@media (max-width: 991.98px)
    .employee-modal
        --name-size: 32px
        --img-width: 350px

        .modal-dialog
            max-width: 100%
            width: 100%
            height: 100vh
            margin: 0

            .modal-content
                height: 100%
                border-radius: 0

@media (max-width: 767.98px)
    .employee-modal
        --name-size: 24px
        --img-width: 250px

        .modal-body
            gap: 24px

@media (max-width: 575.98px)
    .employee-modal
        --name-size: 20px
        --img-width: 250px

        .modal-body
            flex-direction: column

            .img
                margin-top: 24px

            .text
                .name
                    br
                        display: none