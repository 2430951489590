footer
    background: var(--green-color)

    .footer-content
        position: relative
        display: flex
        align-items: center
        justify-content: space-between
        padding-top: 40px
        padding-bottom: 40px
        border-bottom: 1px solid #495347
        z-index: 1
        color: #DED9C7

        a
            color: inherit

            &:hover
                color: white

        .logo
            opacity: 0.4
            transition: opacity .3s

            &:hover
                opacity: 1

        .work
            display: flex
            flex-direction: column
            gap: 4px

        .call

            .phone
                background: url('/imgs/phone-white.svg') 0 50% no-repeat
                background-size: 16px

@media (max-width: 1399.98px)
    footer
        .footer-content
            nav
                ul
                    justify-content: center
                    flex-wrap: wrap
                    max-width: 340px
            .call
                .btn
                    white-space: nowrap
                    img
                        display: none

@media (max-width: 1199.98px)
    footer
        .footer-content
            .work
                max-width: 185px

@media (max-width: 991.98px)
    footer
        .footer-content
            flex-wrap: wrap
            padding-top: 32px
            padding-bottom: 32px

            nav
                width: 100%
                order: -2
                padding-bottom: 32px
                margin-bottom: 32px
                border-bottom: 1px solid #DED9C7

                ul
                    max-width: none

            .work
                max-width: none

@media (max-width: 767.98px)
    footer
        .footer-content
            padding-top: 24px

            nav
                padding-bottom: 24px
                margin-bottom: 24px
                border-bottom: 1px solid #DED9C7

            .work
                width: 100%
                order: -1
                padding-bottom: 24px
                margin-bottom: 24px
                border-bottom: 1px solid #DED9C7

@media (max-width: 575.98px)
    footer
        .footer-content
            .logo
                width: 100%
                order: 1
                margin-top: 24px
                text-align: center

            .call
                width: 100%
                align-items: center