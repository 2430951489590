.review-catalog
    --pad: 32px
    display: flex
    flex-direction: column
    gap: var(--pad)
    margin-bottom: var(--pad)

.review, .answer
    --left: 180px
    --head: 56px
    --spec-bg: #909C86
    display: flex
    flex-wrap: wrap
    gap: var(--pad)
    padding: var(--pad)
    border-radius: 8px
    background: #ECE7E5

    .name
        font-family: var(--gilroy)
        font-size: 18px
        font-weight: bold
        line-height: normal

    .left
        display: flex
        flex-direction: column
        gap: 4px
        width: var(--left)

        .rate
            margin-left: auto

    .right
        flex: 1

        .release-date
            background: var(--spec-bg)

    .bottom
        width: 100%

        &:empty
            display: none

        .answer
            border-radius: 0
            margin-left: calc(var(--pad) * -1)
            margin-right: calc(var(--pad) * -1)
            background: var(--spec-bg)
            color: white
    
    .foot
        display: flex
        width: 100%
        gap: var(--pad)

    .review-head
        display: flex
        justify-content: space-between
        align-items: center
        height: var(--head)
        margin-bottom: 12px

        .user-img
            margin-left: auto
            width: var(--head)
            height: var(--head)
            border-radius: 50%
            overflow: hidden
            border: 2px solid white

            img
                width: 100%

.rate
    display: flex
    align-items: center
    color: white
    font-weight: bold
    font-size: 12px

    .rate-item
        width: 32px
        height: 32px
        border-radius: 50%
        display: flex
        align-items: center
        justify-content: center
        background: #8e9aad
        transition: background .3s

    &.form-rate
        gap: 4px
        margin-right: auto

        input
            display: none

        .rate-item
            cursor: pointer

    &[data-selected="1"]
        .rate-item:nth-child(1)
            background: #ff4c5b
    &[data-selected="2"]
        .rate-item:nth-child(-n+2)
            background: #ff8d29
    &[data-selected="3"]
        .rate-item:nth-child(-n+3)
            background: #ffbf00
    &[data-selected="4"]
        .rate-item:nth-child(-n+4)
            background: #99c21d
    &[data-selected="5"]
        .rate-item:nth-child(-n+5)
            background: #1cbf60

.reactions
    display: flex
    gap: 48px
    align-items: center

    .reaction
        display: flex
        gap: 16px
        font-weight: bold
        align-items: center

        svg
            width: 24px
            height: 24px
            fill: #8e9aad

.collapse-trigger
    display: flex
    cursor: pointer
    margin-top: 4px
    opacity: .7

    span:nth-child(2)
        display: none

    &[aria-expanded="true"]
        span:nth-child(1)
            display: none
        span:nth-child(2)
            display: inline-block

.collapse-wrapper
    .collapse-min-text
        &::after
            content: '...'
    &.showed
        .collapse-min-text
            &::after
                content: ''


.collapsing
    -webkit-transition: none
    transition: none
    display: none

@media (max-width: 1399.98px)
    .review-catalog
        --pad: 24px
    
    .review, .answer
        --left: 160px

@media (max-width: 991.98px)
    .review, .answer

        .name
            font-size: 16px

    .rate
        font-weight: 600

        .rate-item
            width: 24px
            height: 24px

@media (max-width: 767.98px)
    .review, .answer
        flex-direction: column

        .left
            width: auto
            margin-bottom: -16px

            .review-head
                margin-bottom: 0

                .user-img
                    margin-left: 0

            .rate
                margin-left: 0

        .right
            position: relative

            .review-head
                position: absolute
                bottom: 100%
                margin-bottom: 37px
                left: 0
                width: 100%
                padding-left: 72px
            
            .release-date
                font-size: 14px

        .foot
            .left
                display: none

    .answer
        .right
            .review-head
                margin-bottom: 8px