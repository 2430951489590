.form-modal
    --pad: 70px

    .modal-dialog
        max-width: 760px

    .modal-content
        border: none
        border-radius: 0
        background: #F1EBD9

    .modal-body
        padding: var(--pad)
        display: flex
        flex-direction: column
        align-items: flex-start
        background: inherit

        .close-btn
            position: absolute
            top: 0
            right: 0

        .contact-form
            width: 100%

            .title
                font-family: var(--gilroy)
                font-size: 48px
                font-weight: 500
                line-height: normal
                margin-bottom: 34px

@media (max-width: 1199.98px)
    .form-modal
        --pad: 40px

        .modal-body
            .contact-form
                .title
                    font-size: 32px
                    white-space: nowrap

@media (max-width: 991.98px)
    .form-modal

        .modal-dialog
            max-width: 100%
            width: 100%
            height: 100vh
            margin: 0

            .modal-content
                height: 100%
                border-radius: 0

@media (max-width: 767.98px)
    .form-modal
        --pad: 24px
    
        .modal-body
            .contact-form
                .title
                    font-size: 24px
                    margin-bottom: 24px