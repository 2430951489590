.team-slider
    --img-height: 370px
    margin-top: var(--mt)
    display: flex
    gap: 32px
    align-items: stretch

    .mobile-head
        display: none

.employee
    display: flex
    flex-direction: column

    .img
        border-radius: 16px
        overflow: hidden

        img
            height: var(--img-height)

    .name
        margin-top: 16px
        font-family: var(--gilroy)
        font-size: 32px
        font-weight: 500
        line-height: normal

    .major
        color: black

.head-square
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    gap: 32px
    border-radius: 16px
    background: #EFE8E5
    min-width: 356px
    height: var(--img-height)
    z-index: 2

    .logo

        img
            width: 235px

    .btns-controls
        display: flex
        justify-content: space-between
        align-items: center
        gap: 16px
        padding: 0 6px
        min-width: 235px

@media (max-width: 1723.98px)
    .team-slider
        --img-height: 293px

    .head-square
        gap: 24px

@media (max-width: 1399.98px)
    .team-slider
        gap: 24px
        --img-height: 370px

    .head-square
        .btns-controls
            padding: 0

@media (max-width: 1199.98px)
    .team-slider
        --img-height: 303px

    .head-square
        min-width: auto
        padding: 0 32px
        .logo
            img
                width: 185px
    .employee
        .name
            font-size: 26px

@media (max-width: 991.98px)
    .team-slider
        --img-height: 229px
        flex-direction: column
        gap: 8px

        .mobile-head
            display: flex
        .head-square
            display: none

        .swiper
            width: 100%

    .employee
        .name
            margin-top: 10px
            font-size: 18px

@media (max-width: 767.98px)
    .team-slider
        gap: 0

    .employee
        --img-height: 259px

@media (max-width: 575.98px)
    .employee
        .img
            position: relative
            width: 100%

            img
                position: relative
                left: 50%
                transform: translateX(-50%)

@media (max-width: 439.98px)
    .employee
        display: inline-flex