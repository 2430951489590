.service-catalog
    --item-mb: 32px
    position: relative
    margin-bottom: calc(var(--item-mb) * -1)
    
    .row
        --bs-gutter-x: var(--item-mb)
        text-align: center

        > .anim:nth-child(3n+2)
            --delay: .1s
        > .anim:nth-child(3n+3)
            --delay: .2s

    .service-item
        text-align: left
        margin-bottom: var(--item-mb)

@media (max-width: 1199.98px)
    .service-catalog
        .row
            > .anim:nth-child(n)
                --delay: 0s
            > .anim:nth-child(2n+2)
                --delay: .1s

@media (max-width: 991.98px)
    .service-catalog
        --item-mb: 24px

@media (max-width: 767.98px)
    .service-catalog
        .row
            > .anim:nth-child(n)
                --delay: 0s

@media (max-width: 575.98px)
    .service-catalog
        --item-mb: 18px