.service-modal
    --pad: 48px

    .modal-dialog
        max-width: 1520px

    .modal-content
        border: none
        border-radius: 0
        background: #ECE7E5

    .modal-body
        padding: 0
        display: flex
        flex-direction: column
        background: inherit

        .close-btn
            position: absolute
            top: 0
            right: 0

        .top
            display: flex
            align-items: center
            background: var(--green-color)

            .img
                width: 50%
                overflow: hidden

                img
                    display: block
                    max-width: 100%

            .right
                display: flex
                flex-direction: column
                gap: 20px
                width: 50%
                padding: var(--pad)
                color: #DED9C7

                .expecting
                    .title
                        font-weight: 700
                        text-transform: uppercase

                    ul
                        margin-bottom: 0
                        padding-left: 3rem
                
                .btns
                    gap: 16px

        .bottom
            --mb-text: 32px
            padding: var(--pad)

            .text
                margin-bottom: var(--mb-text)

                &:last-child
                    margin-bottom: 0
                
                .title
                    margin-bottom: var(--mb-text)
                    font-weight: 500

.conditions
    display: flex
    gap: 48px
    font-weight: 700

    > *
        display: flex
        flex-direction: column
        gap: 4px

        .value
            font-size: 1.5em

    .time
        padding-left: 48px
        background: url('/imgs/timer.svg') top left no-repeat

    .cost
        padding-left: 48px
        background: url('/imgs/money.svg') top left no-repeat

@media (max-width: 1599.98px)
    .service-modal

        .modal-dialog
            max-width: 100%
            width: 100%
            height: 100vh
            margin: 0

            .modal-content
                height: 100%
                border-radius: 0

@media (max-width: 1399.98px)
    .service-modal
        .modal-body
            .top
                .img
                    margin: var(--pad)
                    margin-right: 0
                    border-radius: 8px
            .bottom
                --mb-text: 24px

@media (max-width: 1199.98px)
    .service-modal
        --pad: 32px

    .conditions
        gap: 32px

@media (max-width: 991.98px)
    .service-modal
        .modal-body
            .top
                .img
                    width: 36%
                .right
                    width: 64%

    .conditions
        gap: 24px
        flex-wrap: wrap

@media (max-width: 767.98px)
    .service-modal
        --pad: 24px
        
        .modal-body
            .top
                flex-direction: column
                padding: 0 var(--pad)

                .img
                    margin: 0
                    margin-top: var(--pad)
                    width: auto
                    max-width: 480px

                .right
                    width: 100%
                    max-width: 480px
                    padding-left: 0
                    padding-right: 0

                    .btns
                        flex-direction: column
                
            .bottom
                --mb-text: 16px