.form-buy
    display: flex

    .text-blocks
        width: 50%
        border-radius: 8px 0 0 8px

    .form-block
        width: 50%
        border-radius: 0 8px 8px 0

.form-block
    --logo-width: 244px
    --img-width: 380px
    --height: 473px
    background: #909C86
    display: flex
    flex-direction: column
    color: #F1EBD9

    .preview
        position: relative
        height: var(--height)
        background: url(/imgs/examples/flower.png) center no-repeat
        background-size: cover

        .logo
            position: absolute
            top: 50%
            right: 46px
            width: var(--logo-width)
            height: var(--logo-width)
            transform: translate(0%, -50%)

            img
                width: 100%
                transform: translateY(-100%) rotate(-90deg)
                transform-origin: right bottom

        .imgs
            position: absolute
            left: 50%
            bottom: 24px
            transform: translateX(-50%)
            width: var(--img-width)

            .img
                position: relative
                border-radius: 16px
                overflow: hidden
                width: 100%
                z-index: 1

                img
                    width: 100%
                
                &.back
                    position: absolute
                    width: 71%
                    bottom: 30px
                    right: 50%
                    margin-right: 15px
                    opacity: 0.8
                    z-index: 0

    .contact-form
        padding: 40px 70px 54px
        
        .title
            font-family: var(--gilroy)
            font-size: 48px
            font-weight: 500
            line-height: normal
            margin-bottom: 16px

@media (max-width: 1723.98px)
    .form-block
        --logo-width: 200px
        --img-width: 360px

        .contact-form
            padding: 48px

            .form-row
                .form-select
                    width: 300px

@media (max-width: 1399.98px)
    .form-block
        --logo-width: 146px
        --img-width: 320px
        --height: 400px

        .preview
            .logo
                right: 24px

        .contact-form
            padding: 40px

            .title
                font-size: 40px

@media (max-width: 1199.98px)
    .form-buy
        flex-direction: column

        .text-blocks
            width: 100%
            border-radius: 8px 8px 0 0
            padding-bottom: var(--mt)
            
        .form-block
            width: 100%
            border-radius: 0 0 8px 8px

    .form-block
        --img-width: 300px
        .contact-form
            .title
                font-size: 32px
                white-space: nowrap

@media (max-width: 991.98px)
    .form-block
        .contact-form
            padding: 24px

@media (max-width: 767.98px)
    .form-block
        --logo-width: 120px
        --img-width: 240px
        --height: 320px

        .contact-form
            .title
                font-size: 24px
                margin-bottom: 24px

@media (max-width: 575.98px)
    .form-block
        --logo-width: 100px

        .preview
            height: 0
            padding-top: 68%
            
            .logo
                right: 16px

            .imgs
                max-width: 50%
        .contact-form
            .title
                font-size: 20px

            .form-row
                .form-select
                    width: 100%
                    max-width: 100%