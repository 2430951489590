.extend-block
    --size: 1.5em
    --text-m: 30px
    background: #F1EBD9
    margin-top: var(--mt)

    .left

        .img
            padding-right: 21px
            display: flex
            justify-content: flex-end

    .right

        .bigger-text
            padding-left: 38px
            font-size: var(--size)
            line-height: 1.25

            p, ul, li
                margin: var(--text-m) 0

            ul
                padding-left: 1.7em

@media (max-width: 1723.98px)
    .extend-block
        .left
            .img
                padding-right: 0
        .right
            .bigger-text
                padding-left: 24px

@media (max-width: 1399.98px)
    .extend-block
        --size: 1.3em
        --text-m: 24px

@media (max-width: 1199.98px)
    .extend-block
        --size: 1.2em
        --text-m: 16px

@media (max-width: 991.98px)
    .extend-block
        --size: 1.1em
        --text-m: 16px
        
        .left
            display: flex
            justify-content: center
            padding: 24px 12px 0

            .img
                border-radius: 8px
                overflow: hidden
                max-width: 420px
                img
                    width: 100%
        .right
            padding-bottom: var(--mt)

            .bigger-text
                padding-left: 0