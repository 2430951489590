.gallery-inside-catalog 
    --item-mb: 32px
    --lost-mb: 64px
    position: relative
    margin-bottom: calc(var(--lost-mb) - var(--mt) - var(--item-mb))
    
    .row
        --bs-gutter-x: var(--item-mb)
        text-align: center

        > .anim:nth-child(4n+2)
            --delay: .1s
        > .anim:nth-child(4n+3)
            --delay: .2s
        > .anim:nth-child(4n+4)
            --delay: .3s

    .gallery-item
        margin-bottom: var(--item-mb)
    
.gallery-item
    display: flex
    position: relative

    &::after
        content: ''
        position: absolute
        width: 100%
        height: 100%
        background: rgba(73, 83, 71, 0.80)
        opacity: 0
        transition: opacity .3s

    &::before
        content: ''
        position: absolute
        top: 50%
        left: 50%
        width: 64px
        height: 64px
        margin-left: -32px
        margin-top: -32px
        border-radius: 50%
        background-color: #495347
        background-image: url('/imgs/eye-white.svg')
        background-position: center
        background-repeat: no-repeat
        background-size: 32px
        opacity: 0
        transition: opacity .3s
        z-index: 1

    &:hover
        &::after, &::before
            opacity: 1

@media (max-width: 1199.98px)
    .gallery-inside-catalog 
        .row
            > .anim:nth-child(n)
                --delay: 0s
            > .anim:nth-child(3n+2)
                --delay: .1s
            > .anim:nth-child(3n+3)
                --delay: .2s

@media (max-width: 991.98px)
    .gallery-inside-catalog
        --item-mb: 24px
        --lost-mb: 50px

@media (max-width: 767.98px)
    .gallery-inside-catalog 
        .row
            > .anim:nth-child(n)
                --delay: 0s
            > .anim:nth-child(2n+2)
                --delay: .1s

@media (max-width: 575.98px)
    .gallery-inside-catalog
        --lost-mb: 40px

        .gallery-item
            max-width: 100%
            width: 356px
 
        .row
            > .anim:nth-child(n)
                --delay: 0s