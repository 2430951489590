.list-slider
    margin-top: var(--mt)

    .list-slide
        display: flex
        flex-direction: column
        gap: 30px

@media (max-width: 1399.98px)
    .list-slider
        .list-slide
            gap: 20px

@media (max-width: 767.98px)
    .list-slider
        .list-slide
            gap: 16px