.page-head
    margin-top: 36px

    .page-title
        margin-bottom: 60px

        h1
            margin-bottom: 0

.breadcrumbs
    margin-bottom: 20px

    ul
        list-style: none
        padding: 0
        margin: 0
        display: flex
        flex-wrap: wrap

        li
            position: relative
            padding-right: 44px

            &::marker
                content: ''

            &::after
                content: ''
                position: absolute
                right: 16px
                top: 0
                width: 13px
                height: 100%
                background: url('/imgs/arrow-right-small-green.svg') 0px 5px no-repeat
                background-size: 13px

            &:last-child
                padding-right: 0

                &::after
                    display: none

            span
                color: #B9A3A3

@media (max-width: 1399.98px)
    .page-head

        .page-title
            margin-bottom: 50px

@media (max-width: 1199.98px)
    .page-head
        margin-top: 32px

        .page-title
            margin-bottom: 40px

@media (max-width: 767.98px)
    .page-head
        margin-top: 24px
    
        .breadcrumbs
            margin-bottom: 16px

        .page-title
            margin-bottom: 32px