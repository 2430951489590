.article-modal
    --pad: 48px

    .modal-dialog
        max-width: 1010px

    .modal-content
        border: none
        border-radius: 0
        background: white

    .modal-body
        padding: var(--pad)
        display: flex
        flex-direction: column
        align-items: flex-start
        background: inherit

        .close-btn
            position: absolute
            top: 0
            right: 0

        .release-date
            margin-bottom: 18px

        .name
            margin-bottom: calc(var(--pad) / 2)

        .img
            margin-left: auto
            margin-right: auto
            width: auto
            overflow: hidden
            border-radius: 8px
            margin-bottom: calc(var(--pad) / 2)

            img
                width: 100%

        .text
            margin-bottom: calc(var(--pad) / 2)

            p
                margin: calc(var(--pad) / 2) 0

        .btn
            margin-left: auto
            margin-right: auto

@media (max-width: 1199.98px)
    .article-modal
        --pad: 32px

@media (max-width: 991.98px)
    .article-modal

        .modal-dialog
            max-width: 100%
            width: 100%
            height: 100vh
            margin: 0

            .modal-content
                height: 100%
                border-radius: 0

@media (max-width: 767.98px)
    .article-modal
        --pad: 24px

        .modal-body
            .release-date
                margin-bottom: var(--pad)

            .name
                margin-bottom: var(--pad)