.litle-about
    --pad-side: 100px
    margin-top: var(--mt)
    overflow: hidden
    border-radius: 16px
    display: flex
    align-items: stretch

    &.own-page
        margin-top: 0

    .content
        width: 50%
        display: flex
        flex-direction: column
        justify-content: center
        gap: 34px
        padding: 0 var(--pad-side)
        background-color: #495347
        background-image: url('/imgs/examples/flower.png')
        background-position: center
        background-repeat: no-repeat
        background-size: cover
        color: white

        .title
            font-family: var(--gilroy)
            font-size: 40px
            font-weight: bold
            line-height: normal
            color: #DED9C7
        
        .figure
            display: inline-flex
            align-items: center
            gap: 32px
            padding: 13px 0 19px
            border-top: 1px solid white
            border-bottom: 1px solid white
            color: #F5EAC3
            font-size: 40px
            font-weight: 900
            line-height: normal
            text-transform: uppercase

            .figure-text
                font-family: var(--dewi)
                font-size: 72px
                font-weight: 900

        .desc
            p
                margin-bottom: 24px

                &:last-child
                    margin-bottom: 0

    .right
        width: 50%
        display: flex

        img
            max-width: 100%

@media (max-width: 1723.98px)
    .litle-about
        --pad-side: 60px

        .content
            padding: var(--pad-side)

@media (max-width: 1399.98px)
    .litle-about
        --pad-side: 40px

        .content
            gap: 24px
            z-index: 1

            .title
                font-size: 32px

            .figure
                gap: 24px
                font-size: 32px

                .figure-text
                    font-size: 64px

            .desc
                p
                    margin-bottom: 16px

        .right
            height: 550px

            img
                max-width: none
                max-height: 100%
                transform: translateX(-50%)
                position: relative
                left: 50%

@media (max-width: 1199.98px)
    .litle-about
        .content
            gap: 16px
            .title
                font-size: 24px

            .figure
                font-size: 24px
                gap: 16px

                .figure-text
                    font-size: 48px
        .right
            height: 486px

@media (max-width: 991.98px)
    .litle-about
        --pad-side: 24px

        .content
            width: 100%

            .title
                font-size: 20px

            .figure
                font-size: 20px
                padding: 10px 0 15px

                .figure-text
                    font-size: 32px

            .desc
                display: flex
                align-items: center
                gap: 16px

                > *
                    width: 50%

                img
                    width: 100%
                    border-radius: 8px

        .right
            display: none

@media (max-width: 575.98px)
    .litle-about

        .content

            .title
                font-size: 18px

            .figure
                font-size: 18px

                .figure-text
                    font-size: 26px

            .desc
                flex-direction: column

                > *
                    width: 100%

                img
                    max-width: 300px