.article-catalog
    --item-mb: 32px
    --lost-mb: 64px
    position: relative
    margin-bottom: calc(var(--lost-mb) - var(--mt) - var(--item-mb))
    
    .row
        --bs-gutter-x: var(--item-mb)

    .article
        margin-bottom: var(--item-mb)

    .pagination
        margin-bottom: var(--item-mb)

.article-tags, .simple-tags
    display: flex
    gap: 8px
    margin-bottom: 48px
    margin-top: -32px

.btn.article-tag
    border-radius: 20px
    padding: 8px 17px

@media (max-width: 1199.98px)
    .article-tags, .simple-tags
        margin-bottom: 32px
        margin-top: -24px

@media (max-width: 991.98px)
    .article-catalog
        --item-mb: 24px
        --lost-mb: 50px
    .article-tags, .simple-tags
        flex-wrap: wrap
        margin-bottom: 24px
        margin-top: -20px

@media (max-width: 575.98px)
    .article-catalog
        --lost-mb: 40px