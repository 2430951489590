.service-item
    --height: 223px
    --img-ratio: 1.287
    position: relative
    display: flex
    align-items: center
    padding: 24px
    height: var(--height)
    border-radius: 16px
    background: #DED9C7
    overflow: hidden

    &::before
        content: ''
        position: absolute
        top: 0
        left: 0
        width: calc(100% - var(--height) * var(--img-ratio) + 2px)
        height: 100%
        background: rgba(222,217,199,1)
        z-index: 1

    .img
        position: absolute
        top: 0
        right: 0
        max-width: calc(var(--height) * var(--img-ratio))
        height: 100%
        display: flex
        align-items: center
        justify-content: flex-end
        overflow: hidden
        border-left: 1px solid #DED9C7

        &::after
            content: ''
            position: absolute
            top: 0
            left: 0
            width: 60%
            height: 100%
            background: rgba(222,217,199,1)
            background: -moz-linear-gradient(left, rgba(222,217,199,1) 0%, rgba(222,217,199,0) 100%)
            background: -webkit-gradient(left top, right top, color-stop(0%, rgba(222,217,199,1)), color-stop(100%, rgba(222,217,199,0)))
            background: -webkit-linear-gradient(left, rgba(222,217,199,1) 0%, rgba(222,217,199,0) 100%)
            background: -o-linear-gradient(left, rgba(222,217,199,1) 0%, rgba(222,217,199,0) 100%)
            background: -ms-linear-gradient(left, rgba(222,217,199,1) 0%, rgba(222,217,199,0) 100%)
            background: linear-gradient(to right, rgba(222,217,199,1) 0%, rgba(222,217,199,0) 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ded9c7', endColorstr='#ded9c7', GradientType=1 )

        img
            height: 100%

    .content
        position: relative
        display: flex
        flex-direction: column
        width: 230px
        z-index: 2

    .name
        position: relative
        margin-bottom: 8px
        padding-bottom: 9px

        &::after
            content: ''
            position: absolute
            bottom: 0
            left: 0
            height: 1px
            width: 65px
            background: #BAB5A3

    .text
        color: #87847C

    .btn
        position: absolute
        right: 8px
        bottom: 8px

        &.like-tag
            right: 16px
            bottom: 16px

@media (max-width: 1399.98px)
    .service-item
        --height: 180px

@media (max-width: 1199.98px)
    .service-item
        --height: 223px

@media (max-width: 991.98px)
    .service-item
        --height: 180px

@media (max-width: 575.98px)
    .service-item
        --height: 160px

        .content
            width: 140px