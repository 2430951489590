:root
    --white-color: #F1EBD9
    --green-color: #495347
    --gilroy: 'Gilroy', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    --dewi: 'RF Dewi', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
    --mt: 100px

@media (min-width: 1724px)
    .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl
        max-width: 1544px

// Breakpoints:
// `sm` applies to x-small devices (portrait phones, less than 576px)
// @media (max-width: 575.98px)

// `md` applies to small devices (landscape phones, less than 768px)
// @media (max-width: 767.98px)

// `lg` applies to medium devices (tablets, less than 992px)
// @media (max-width: 991.98px)

// `xl` applies to large devices (desktops, less than 1200px)
// @media (max-width: 1199.98px)

// `xxl` applies to x-large devices (large desktops, less than 1400px)
// @media (max-width: 1399.98px)

// custom large (large desktops, less than 1724px)
// @media (max-width: 1723.98px)
    
body
    color: var(--green-color)
    font-family: var(--dewi)
    font-size: 16px
    font-style: normal
    font-weight: 400
    line-height: 150%
    background: #F8F4F2

    &.space-64
        --mt: 64px

a
    color: var(--green-color)
    text-decoration: none
    transition: color .3s

ul
    padding-left: 1.7rem

    li
        &::marker
            content: '•   '

.phone
    position: relative
    display: inline-block
    font-size: 20px
    font-weight: 700
    padding-left: 24px
    background: url('/imgs/phone-green.svg') 0 50% no-repeat
    background-size: 16px

.btn
    box-shadow: none !important
    display: inline-flex
    justify-content: center
    align-items: center

    svg
        width: 20px
        stroke: var(--bs-btn-color)

    &:hover, &:focus, &:active

        svg
            stroke: var(--bs-btn-hover-color)

    &.btn-square
        padding-left: 5px
        padding-right: 5px
        min-width: 48px

.btn-mini
    border: none !important
    padding: 6px 12px
    gap: 8px
    border-radius: 4px
    line-height: normal
    --bs-btn-color: #fff
    --bs-btn-bg: #909C86
    --bs-btn-hover-color: #fff
    --bs-btn-hover-bg: #90AC78
    --bs-btn-active-color: #fff
    --bs-btn-active-bg: #90AC78
    --bs-btn-disabled-color: #fff
    --bs-btn-disabled-bg: #909C86

    &.btn-yellow
        --bs-btn-color: var(--green-color)
        --bs-btn-bg: #F5EAC3
        --bs-btn-hover-color: var(--green-color)
        --bs-btn-hover-bg: #FFE99B
        --bs-btn-active-color: var(--green-color)
        --bs-btn-active-bg: #FFE99B
        --bs-btn-disabled-color: var(--green-color)
        --bs-btn-disabled-bg: #F5EAC3

.btn-round
    border: none !important
    min-width: 48px
    height: 48px
    padding: 0
    border-radius: 100px
    line-height: normal
    font-size: 12px
    font-weight: 700
    --bs-btn-color: #fff
    --bs-btn-bg: #495347
    --bs-btn-hover-color: #fff
    --bs-btn-hover-bg: #617A5C
    --bs-btn-active-color: #fff
    --bs-btn-active-bg: #617A5C
    --bs-btn-disabled-color: #fff
    --bs-btn-disabled-bg: #495347

    &.like-tag
        padding: 7px 16px 10px 16px
        height: auto

.btn-primary, .btn-transparent
    border: none !important
    padding: 14px 24px
    gap: 10px
    line-height: 20px
    font-weight: bold
    border-radius: 0
    --bs-btn-color: #fff
    --bs-btn-bg: #909C86
    --bs-btn-hover-color: #fff
    --bs-btn-hover-bg: #90AC78
    --bs-btn-active-color: #fff
    --bs-btn-active-bg: #90AC78
    --bs-btn-disabled-color: #fff
    --bs-btn-disabled-bg: #909C86

.btn-dark
    border: none !important
    padding: 15px 31px
    gap: 10px
    line-height: 20px
    font-weight: bold
    border-radius: 0
    --bs-btn-color: #fff
    --bs-btn-bg: var(--green-color)
    --bs-btn-hover-color: #fff
    --bs-btn-hover-bg: #617A5C
    --bs-btn-active-color: #fff
    --bs-btn-active-bg: #617A5C
    --bs-btn-disabled-color: #fff
    --bs-btn-disabled-bg: var(--green-color)

    &.btn-invert
        --bs-btn-color: var(--green-color)
        --bs-btn-bg: #F1EBD9
        --bs-btn-hover-color: var(--green-color)
        --bs-btn-hover-bg: #FFE99B
        --bs-btn-active-color: var(--green-color)
        --bs-btn-active-bg: #FFE99B
        --bs-btn-disabled-color: var(--green-color)
        --bs-btn-disabled-bg: #F1EBD9

.btn-secondary
    padding: 13px 23px
    gap: 10px
    line-height: 20px
    font-weight: bold
    border-radius: 0
    --bs-btn-color: var(--green-color)
    --bs-btn-bg: transparent
    --bs-btn-border-color: var(--green-color)
    --bs-btn-hover-color: #8FAC78
    --bs-btn-hover-bg: transparent
    --bs-btn-hover-border-color: #8FAC78
    --bs-btn-active-color: #8FAC78
    --bs-btn-active-bg: transparent
    --bs-btn-active-border-color: #8FAC78
    --bs-btn-disabled-color: var(--green-color)
    --bs-btn-disabled-bg: transparent
    --bs-btn-disabled-border-color: var(--green-color)

    &.btn-white
        --bs-btn-color: #fff
        --bs-btn-border-color: #fff
        --bs-btn-hover-color: #FFE99B
        --bs-btn-hover-border-color: #FFE99B
        --bs-btn-active-color: #FFE99B
        --bs-btn-active-border-color: #FFE99B
        --bs-btn-disabled-color: #fff
        --bs-btn-disabled-border-color: #fff

.btns-pagination
    display: flex
    gap: 16px
    width: auto !important
    align-items: center
    --def-color: var(--green-color)

    &.white
        --def-color: white

    > span
        width: 8px
        height: 8px
        border-radius: 8px
        background: transparent
        border: 1px solid var(--def-color)
        opacity: 1
        margin: 0 !important
        transition: background .3s

        &:hover, &.swiper-pagination-bullet-active
            background: var(--def-color)

.close-btn
    padding: 24px

    svg
        display: block
        width: 16px
        height: 16px
        fill: white

.btn-transparent
    background: transparent !important

    svg
        fill: #909C86
        stroke: #909C86

    &:hover

        svg
            fill: var(--green-color)
            stroke: var(--green-color)

.btns
    display: flex
    gap: 48px

    .btns-link
        display: flex
        gap: 8px

.big-h2
    font-family: var(--gilroy)
    font-size: 64px
    font-weight: bold
    line-height: normal
    margin-bottom: 0

.big-h3
    color: #909C86
    font-family: var(--gilroy)
    font-size: 32px
    font-weight: bold
    line-height: normal

.block-title
    font-family: var(--gilroy)
    font-size: 24px
    font-weight: bold
    line-height: normal
    text-transform: uppercase

.head
    display: flex
    align-items: center
    gap: 48px
    margin-bottom: 16px

    .btns-link
        .btn
            white-space: nowrap

.btns-nav
    display: flex

    .btn
        margin-right: -1px

        &:hover
            z-index: 1

        &:last-child
            margin-right: 0

.big-phone
    font-size: 36px
    font-weight: normal
    line-height: normal

.messengers
    display: flex
    flex-direction: column
    align-items: center
    gap: 2px

    .links
        display: flex
        align-items: center
        gap: 13px

input.form-control
    min-height: auto !important
    height: auto !important

textarea.form-control
    min-height: 166px !important

.form-control
    --border-color: #909C86
    border: none
    box-shadow: none
    background: transparent
    border-radius: 0
    color: var(--green-color)
    font-size: 16px
    font-weight: 400
    line-height: 24px !important
    border-bottom: 2px solid var(--border-color) !important
    padding: 0 0 16px !important

    &[type="file"]
        border: 1px solid transparent !important
        border-bottom: 2px solid var(--border-color) !important
        padding: 12px !important

    &:focus
        border: none
        box-shadow: none
        background: transparent

.file-field
    label
        margin-bottom: 0

.form-select
    border-radius: 24px
    background: #495347
    border-color: #495347 !important
    box-shadow: none !important
    color: white
    font-weight: 700
    line-height: normal
    text-transform: uppercase
    padding: 8px 40px 8px 16px
    background-image: url('/imgs/triangle-white.svg')
    background-position: calc(100% - 16px) 50%
    background-repeat: no-repeat
    background-size: 11px

.form-row
    width: 100%
    display: flex
    align-items: center
    justify-content: space-between
    gap: 24px

    .form-control, .form-select
        max-width: 420px

    .dynamic-text
        color: inherit

.form-floating

    > label
        padding: 0 0 16px
        height: auto

    .form-control

        &:focus, &:not(:placeholder-shown)
            ~ label
                color: inherit
                transform: scale(0.8) translateY(-22px) translateX(-3px)
                opacity: 0.7

                &::after
                    display: none

h1, .near-h1
    font-family: var(--gilroy)
    font-size: 64px
    font-weight: bold
    line-height: normal

.near-h1
    font-size: 56px

.centered-img
    position: relative
    width: 100%
    padding-top: 100%
    height: 0

    img
        position: absolute
        top: 50%
        left: 50%
        width: 100%
        height: auto
        transform: translate(-50%, -50%)

.fancybox__container
    --fancybox-bg: rgba(73, 83, 71, .9)

.f-button
    border-radius: 0px
    border: 1px solid white
    background: transparent

    &:disabled
        border-color: rgba(255, 255, 255, .5)

    &:hover:not(:disabled)
        background: transparent
        border-color: #FFE99B

        svg
            stroke: #FFE99B

.fancybox__toolbar__column
    gap: 4px

.fancybox__nav

    .f-button
        background: url('/imgs/arrow-right-small.svg') center no-repeat

        &.is-prev
            transform: translateY(-50%) rotate(180deg) !important

        &:hover:not(:disabled)
            background: url('/imgs/arrow-right-small-yellow.svg') center no-repeat

        svg
            display: none

.fancybox__infobar
    font-family: var(--dewi)
    font-size: 18px

.pagination
    display: flex
    justify-content: center
    gap: 48px

.space
    margin-top: var(--mt)

@media (max-width: 1399.98px)
    :root
        --mt: 80px
    body
        &.space-64
            --mt: 56px
    .big-h2, h1
        font-size: 56px
    .near-h1
        font-size: 48px
    .head
        gap: 24px
    .big-phone
        font-size: 28px

@media (max-width: 1199.98px)
    :root
        --mt: 60px
    body
        &.space-64
            --mt: 48px
    body
        font-size: 15px

    .btn
        --bs-btn-font-size: 15px

    .big-h2, h1
        font-size: 40px

    .near-h1
        font-size: 36px

    .big-h3
        font-size: 26px

    .block-title
        font-size: 22px

    .phone
        font-size: 18px

    .big-phone
        font-size: 24px

    .form-control
        font-size: 15px

@media (max-width: 991.98px)
    body
        font-size: 14px

    .btn
        --bs-btn-font-size: 14px

    .big-h2, h1
        font-size: 32px

    .near-h1
        font-size: 28px

    .big-h3
        font-size: 24px

    .block-title
        font-size: 18px

    .phone
        font-size: 16px

    .big-phone
        font-size: 20px

    .btns
        gap: 24px

    .close-btn
        padding: 16px !important

    .pagination
        gap: 32px

@media (max-width: 767.98px)
    :root
        --mt: 40px
    body
        &.space-64
            --mt: 40px
    body
        font-size: 13px

    .btn-primary
        padding: 12px 19px

    .btn-secondary
        padding: 11px 18px

    .btn-dark
        padding: 12px 24px

    .btn
        gap: 16px

    .big-h2
        font-size: 24px

    .big-h3
        font-size: 20px

    .phone
        font-size: 15px

    .big-phone
        font-size: 18px

    .head
        flex-wrap: wrap
        gap: 16px
        justify-content: space-between

        .big-h2
            width: 100%

    textarea.form-control
        min-height: 114px !important
    
    .pagination
        gap: 16px

@media (max-width: 575.98px)
    .big-h2
        font-size: 20px

    .big-h3
        font-size: 18px

    .phone
        font-size: 14px

    .big-phone
        font-size: 16px
        white-space: nowrap

    .btn-round
        min-width: 40px
        height: 40px

    .head

        .btns-pagination
            width: 100% !important
            justify-content: center

    .form-row
        flex-direction: column
        align-items: flex-start
        gap: 16px