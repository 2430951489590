.article
    --pad: 32px
    --left-width: 382px
    display: flex
    gap: var(--pad)
    padding: var(--pad)
    border-radius: 8px
    background: #ECE7E5
    align-items: center
    position: relative

    .img
        border-radius: 8px
        overflow: hidden
        width: var(--left-width)

        img
            display: block
            width: 100%

    .content
        flex: 1
        display: flex
        flex-direction: column
        align-items: flex-start
        gap: 24px

        .release-date
            background: #909C86

        .name
            color: #909C86
            font-family: var(--gilroy)
            font-size: 32px
            font-weight: bold
            line-height: normal

        .preview

            p
                margin: 0

.release-date
    display: inline-flex
    padding: 8px 16px
    border-radius: 20px
    background: var(--green-color)
    color: #F8F4F2
    font-family: var(--gilroy)
    font-size: 16px
    font-weight: bold
    line-height: 12px

@media (max-width: 1399.98px)
    .article
        --pad: 24px

        .content
            gap: 16px

            .name
                font-size: 24px

@media (max-width: 1199.98px)
    .article

        .content

            .name
                font-size: 20px

@media (max-width: 991.98px)
    .article
        --pad: 16px
        --left-width: 210px
        align-items: flex-start

        .content
            position: relative

            .release-date
                font-size: 14px
                padding: 7px 12px 5px 12px
                position: absolute
                top: 0
                left: calc((var(--left-width) + var(--pad)) * -1)

            .name
                font-size: 18px

        .img
            margin-top: calc(28px + var(--pad))

@media (max-width: 767.98px)
    .article
        --left-width: 300px
        flex-direction: column

        .content
            .release-date
                position: relative
                left: 0

        .img
            margin-top: 0
            margin-left: auto
            margin-right: auto
            max-width: 100%