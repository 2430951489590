.gallery-catalog
    --item-mb: 32px
    --lost-mb: 64px
    position: relative
    margin-bottom: calc(var(--lost-mb) - var(--mt) - var(--item-mb))
    
    .row
        text-align: center

        > .anim:nth-child(3n+2)
            --delay: .1s
        > .anim:nth-child(3n+3)
            --delay: .2s

    .gallery-group
        margin-bottom: var(--item-mb)

.gallery-group
    border-radius: 8px 8px 16px 16px
    overflow: hidden
    background: #F1EBD9

    .img
        padding-top: 62%

        &::after
            content: ''
            position: absolute
            bottom: 0
            left: 0
            width: 100%
            height: 50%
            background: rgba(241,235,217,1)
            background: -moz-linear-gradient(bottom, rgba(241,235,217,1) 0%, rgba(241,235,217,0) 100%)
            background: -webkit-gradient(left bottom, left top, color-stop(0%, rgba(241,235,217,1)), color-stop(100%, rgba(241,235,217,0)))
            background: -webkit-linear-gradient(bottom, rgba(241,235,217,1) 0%, rgba(241,235,217,0) 100%)
            background: -o-linear-gradient(bottom, rgba(241,235,217,1) 0%, rgba(241,235,217,0) 100%)
            background: -ms-linear-gradient(bottom, rgba(241,235,217,1) 0%, rgba(241,235,217,0) 100%)
            background: linear-gradient(to top, rgba(241,235,217,1) 0%, rgba(241,235,217,0) 100%)
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f1ebd9', endColorstr='#f1ebd9', GradientType=0 )

    .content
        position: relative
        display: flex
        justify-content: space-between
        align-items: center
        padding: 0px 8px 8px 24px
        background: inherit
        z-index: 1

@media (max-width: 1199.98px)
    .gallery-catalog
        .row
            > .anim:nth-child(n)
                --delay: 0s
            > .anim:nth-child(2n+2)
                --delay: .1s

@media (max-width: 991.98px)
    .gallery-catalog
        --item-mb: 24px
        --lost-mb: 50px

@media (max-width: 575.98px)
    .gallery-catalog
        --lost-mb: 40px

        .gallery-group
            max-width: 100%
            width: 350px

        .row
            > .anim:nth-child(n)
                --delay: 0s