.contact-screen
    --pt: 80px
    --bread-mb: 18px
    --left-top-minus: calc(var(--pt) / 2 - var(--bread-mb) / 2 - var(--pt))
    position: relative
    margin-top: var(--mt)

    &::before, &::after
        content: ''
        position: absolute
        top: 0
        width: 50%
        height: 100%

    &::before
        left: 0
        background: #909C86

    &::after
        right: 0
        background: #F1EBD9

    &.own-page
        margin-top: -1px

        .contact-content

            .left
                margin-top: var(--left-top-minus)
                gap: 0px

                .breadcrumbs a, .breadcrumbs span, h1
                    color: #F1EBD9
                .breadcrumbs
                    margin-bottom: var(--bread-mb)
                    ul
                        flex-wrap: nowrap
                        li
                            &::after
                                background: url("/imgs/arrow-right-small.svg") 0px 7px no-repeat
                                background-size: 13px
                h1
                    margin-bottom: 0

                .big-phone
                    margin-top: 13px

    .container
        position: relative
        z-index: 1
        display: flex

    .map
        position: absolute
        right: 50%
        bottom: 0
        z-index: 2
        height: 422px
        width: 50%

        iframe
            width: 100%

    .contact-content
        display: flex
        width: 50%
        padding: var(--pt) 0 calc(53px + 422px)
        gap: 37px

        .left
            display: flex
            flex-direction: column
            gap: 14px

            .title
                color: #DED9C7
                font-family: var(--gilroy)
                font-size: 48px
                font-weight: 500
                line-height: normal

        .right
            display: flex
            flex-direction: column
            gap: 25px

            a
                color: white

            .messengers
                flex-direction: row
                gap: 20px

                .title
                    width: 113px

    .contact-form
        width: 50%
        padding: var(--pt) 70px 74px

        .title
            font-family: var(--gilroy)
            font-size: 48px
            font-weight: 500
            line-height: normal
            margin-bottom: 34px

.classic-form
    display: flex
    flex-direction: column
    gap: 30px

    &.white
        color: white

        .form-control
            --border-color: white
            color: white

    .btns
        gap: 24px
        align-items: center

        p
            margin: 0
            font-size: 12px
            line-height: 20px

@media (max-width: 1723.98px)
    .contact-screen
        --pt: 60px
        --bread-mb: 8px

        .contact-form
            padding: var(--pt)

@media (max-width: 1399.98px)
    .contact-screen
        .contact-content
            gap: 16px

            .left
                .title, h1
                    font-size: 40px
            .right
                gap: 16px

                .messengers
                    flex-direction: column
                    gap: 4px
                    align-items: flex-start

                    .title
                        width: auto
        .contact-form
            .title
                font-size: 40px
                margin-bottom: 32px

    .classic-form
        gap: 24px

@media (max-width: 1199.98px)
    .contact-screen
        --pt: 40px
        --bread-mb: 0px

        &.own-page .contact-content .left .big-phone
            margin-top: 0

        .contact-content
            flex-direction: column
            padding-right: 24px
            .left
                --gap: 16px
                flex-direction: row
                flex-wrap: wrap
                gap: var(--gap) !important

                .title, h1
                    width: 100%
                    font-size: 32px
                .address
                    width: calc(50% - var(--gap) / 2)
                .big-phone
                    width: calc(50% - var(--gap) / 2)
                    text-align: right

            .right
                flex-direction: row
                flex-wrap: wrap
                justify-content: space-between

                .messengers
                    width: 100%
                    flex-direction: row
                    align-items: center
                    gap: 16px
                    justify-content: space-between
    
        .contact-form
            .title
                font-size: 32px
                white-space: nowrap

    .classic-form
        .btns
            flex-direction: column
            gap: 16px
            text-align: center

            br
                display: none

@media (max-width: 991.98px)
    .contact-screen
        --pt: 24px
        overflow: hidden

        &.own-page
            .contact-content
                order: 0

                .left
                    margin-top: 0

        &::before, &::after
            display: none

        .container
            flex-direction: column

        .contact-content
            position: relative
            width: 100%
            padding: var(--pt) 0
            order: 1
            background: #909C86

        .contact-form
            position: relative
            width: 100%
            background: #F1EBD9
            padding-left: 0
            padding-right: 0

        .contact-content, .contact-form
            &::before
                content: ''
                position: absolute
                top: 0
                left: 50%
                transform: translateX(-50%)
                height: 100%
                width: 100vw
                background: inherit
                z-index: -1

        .map
            position: relative
            right: auto
            width: 100%

@media (max-width: 767.98px)
    .contact-screen
        .contact-content
            .left
                .title
                    font-size: 24px
        .contact-form
            .title
                font-size: 24px
                margin-bottom: 24px

@media (max-width: 575.98px)
    .contact-screen
        .contact-content
            .left
                .title
                    font-size: 20px
        .contact-form
            .title
                font-size: 20px