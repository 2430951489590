header
    position: relative
    display: flex
    align-items: center
    justify-content: space-between
    height: 125px
    border-bottom: 1px solid #495347
    z-index: 3

    &.header-white
        border-color: #F1EBD9
        color: var(--white-color)
        
        a
            color: var(--white-color)

        .call

            .phone
                background: url('/imgs/phone-white.svg') 0 50% no-repeat
                background-size: 16px

        .burger-menu
            background: transparent
            border: 1px solid white

.burger-menu
    display: none
    position: relative
    align-items: center
    justify-content: center
    cursor: pointer
    padding: 12px
    background: #909C86

    svg
        width: 24px
        height: 24px
        stroke: white


nav
    .close-btn
        display: none
        position: absolute
        top: 16px
        right: 16px

    .messengers, .call
        display: none

    ul
        list-style: none
        margin: 0
        padding: 0
        display: flex
        gap: 24px

        li

            &::marker
                content: ''

            a
                font-family: var(--gilroy)
                font-weight: bold

.call
    display: flex
    flex-direction: column
    gap: 6px
    align-items: flex-end

@media (max-width: 1723.98px)
    nav
        ul
            gap: 14px

@media (max-width: 1399.98px)
    header
        .messengers
            .title
                display: none

            .links
                gap: 8px
        .call
            .btn
                img
                    display: none

@media (max-width: 1199.98px)
    nav
        ul
            flex-wrap: wrap
            max-width: 360px

@media (max-width: 991.98px)
    .burger-menu
        display: flex

    header
        height: 110px

        nav
            display: flex
            position: fixed
            top: 0
            left: 0
            width: 100vw
            height: 100vh
            padding: 80px 0 40px
            background: #F8F4F2
            flex-direction: column
            gap: 32px
            align-items: center
            color: var(--green-color)
            z-index: 9
            overflow-y: auto
            visibility: hidden
            opacity: 0
            transition: opacity .3s, visibility 0s .3s

            .close-btn
                display: block
                position: fixed

            a
                font-size: 20px
                color: var(--green-color) !important

            ul
                width: 270px
                max-width: none
                flex-direction: column
                gap: 24px

            .messengers
                width: 270px
                align-items: flex-start
                gap: 16px

                .title
                    display: block
                    font-size: 20px

            &.show
                opacity: 1
                visibility: visible
                transition: opacity .3s, visibility 0s

@media (max-width: 767.98px)
    header
        .messengers
            display: none
        nav
            .messengers
                display: flex

@media (max-width: 575.98px)
    header
        height: 100px

        .call
            display: none
            
        nav
            a
                font-size: 18px

            .messengers
                .title
                    font-size: 18px

            .call
                display: flex
                align-items: flex-start
                width: 270px
                
                .phone
                    background: url(/imgs/phone-green.svg) 0 50% no-repeat !important
                    background-size: 16px !important