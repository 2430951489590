.team-catalog
    --img-height: 370px
    --item-mb: 48px
    --lost-mb: 64px
    position: relative
    margin-bottom: calc(var(--lost-mb) - var(--mt) - var(--item-mb))
    
    .row
        text-align: center

        > .anim:nth-child(4n+2)
            --delay: .1s
        > .anim:nth-child(4n+3)
            --delay: .2s
        > .anim:nth-child(4n+4)
            --delay: .3s

    .employee
        display: inline-flex
        margin-bottom: var(--item-mb)
        text-align: left
        max-width: 100%

@media (max-width: 1723.98px)
    .team-catalog
        --img-height: 318px

        .employee
            display: flex

            .img
                position: relative
                width: 100%
                padding-top: 104%
                height: 0

                img
                    position: absolute
                    top: 50%
                    left: 50%
                    width: 100%
                    height: auto
                    transform: translate(-50%, -50%)

@media (max-width: 1399.98px)
    .team-catalog
        --item-mb: 40px

@media (max-width: 1199.98px)
    .team-catalog
        .row
            > .anim:nth-child(n)
                --delay: 0s
            > .anim:nth-child(3n+2)
                --delay: .1s
            > .anim:nth-child(3n+3)
                --delay: .2s

@media (max-width: 767.98px)
    .team-catalog
        --item-mb: 32px

        .row
            > .anim:nth-child(n)
                --delay: 0s
            > .anim:nth-child(2n+2)
                --delay: .1s

@media (max-width: 575.98px)
    .team-catalog

        .employee
            width: 295px
 
        .row
            > .anim:nth-child(n)
                --delay: 0s