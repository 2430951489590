.js-hidden.hidden .anim-children > *,
.hidden.anim-children > *,
.js-hidden.hidden .anim,
.anim.hidden
    opacity: 0
    pointer-events: none

.anim,
.anim-children > *
    --duration: .8s
    --delay: 0s
    -webkit-transition: opacity var(--duration) ease, -webkit-transform var(--duration) ease-out
    transition: opacity var(--duration) ease, transform var(--duration) ease-out
    -webkit-transition-delay: var(--delay)
    transition-delay: var(--delay)

.anim-children
    --offdelay: 0s

.anim-children > *:nth-child(1)
    --delay: calc(var(--offdelay) + 0s)
.anim-children > *:nth-child(2)
    --delay: calc(var(--offdelay) + .2s)
.anim-children > *:nth-child(3)
    --delay: calc(var(--offdelay) + .4s)
.anim-children > *:nth-child(4)
    --delay: calc(var(--offdelay) + .6s)
.anim-children > *:nth-child(5)
    --delay: calc(var(--offdelay) + .8s)
.anim-children > *:nth-child(6)
    --delay: calc(var(--offdelay) + 1s)
.anim-children > *:nth-child(7)
    --delay: calc(var(--offdelay) + 1.2s)
.anim-children > *:nth-child(8)
    --delay: calc(var(--offdelay) + 1.4s)
.anim-children > *:nth-child(9)
    --delay: calc(var(--offdelay) + 1.6s)
.anim-children > *:nth-child(10)
    --delay: calc(var(--offdelay) + 1.8s)
.anim-children > *:nth-child(11)
    --delay: calc(var(--offdelay) + 2s)
.anim-children > *:nth-child(12)
    --delay: calc(var(--offdelay) + 2.2s)

.anim-children > a, .anim-children > span
  display: inline-block

.js-hidden.hidden .to-top.anim-children > *,
.hidden.to-top.anim-children > *,
.js-hidden.hidden .anim.to-top,
.anim.hidden.to-top
    -webkit-transform: translateY(50px)
    transform: translateY(50px)

.js-hidden.hidden .to-bottom.anim-children > *,
.hidden.to-bottom.anim-children > *,
.js-hidden.hidden .anim.to-bottom,
.anim.hidden.to-bottom
    -webkit-transform: translateY(-50px)
    transform: translateY(-50px)

.js-hidden.hidden .anim.to-left,
.anim.hidden.to-left
    -webkit-transform: translateX(50px)
    transform: translateX(50px)

.js-hidden.hidden .anim.to-right,
.anim.hidden.to-right
    -webkit-transform: translateX(-50px)
    transform: translateX(-50px)

.js-hidden.hidden .anim.to-left-train,
.anim.hidden.to-left-train
    -webkit-transform: translateX(100%)
    transform: translateX(100%)

.anim.to-left-train
    -webkit-transition: opacity calc(var(--duration)*0.5) ease, -webkit-transform var(--duration) ease-out
    transition: opacity calc(var(--duration)*0.5) ease, transform var(--duration) ease-out
    -webkit-transition-delay: var(--delay)
    transition-delay: var(--delay)

[data-delay="1"]
    --delay: 0.1s
    --offdelay:0.1s
[data-delay="2"]
    --delay: 0.2s
    --offdelay:0.2s
[data-delay="4"]
    --delay: 0.4s
    --offdelay:0.4s
[data-delay="6"]
    --delay: 0.6s
    --offdelay:0.6s
[data-delay="8"]
    --delay: 0.8s
    --offdelay:0.8s
[data-delay="10"]
    --delay: 1s
    --offdelay:1s
[data-delay="12"]
    --delay: 1.2s
    --offdelay:1.2s
[data-delay="14"]
    --delay: 1.4s
    --offdelay:1.4s
[data-delay="16"]
    --delay: 1.6s
    --offdelay:1.6s
[data-delay="18"]
    --delay: 1.8s
    --offdelay:1.8s
[data-delay="20"]
    --delay: 2s
    --offdelay:2s
[data-delay="22"]
    --delay: 2.2s
    --offdelay:2.2s
[data-delay="24"]
    --delay: 2.4s
    --offdelay:2.4s
[data-delay="26"]
    --delay: 2.6s
    --offdelay:2.6s