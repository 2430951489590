.screen-about
    background: url("/imgs/about-bg.jpg") center no-repeat
    background-size: cover
    margin-top: var(--mt)

    &.second-image
        background: url("/imgs/about-bg-2.jpg") center no-repeat
        background-size: cover

    .content
        position: relative
        display: flex
        flex-direction: column
        align-items: center
        justify-content: center
        gap: 30px
        min-height: 717px
        color: #DED9C7
        text-align: center
        font-family: var(--gilroy)
        font-size: 48px
        font-weight: 500
        line-height: normal

        .bg-title
            position: absolute
            left: 0
            top: 50%
            transform: translateX(-50%) rotate(-90deg)
            transform-origin: top center
            text-transform: uppercase
            opacity: .4

        .text
            max-width: 63%

@media (max-width: 1399.98px)
    .screen-about
        .content
            font-size: 40px

@media (max-width: 1199.98px)
    .screen-about
        .content
            font-size: 32px
            min-height: 600px
            gap: 24px

@media (max-width: 991.98px)
    .screen-about
        .content
            font-size: 24px
            min-height: 500px

@media (max-width: 767.98px)
    .screen-about
        .content
            font-size: 20px
            min-height: 400px
            gap: 16px
            padding: 40px 0

@media (max-width: 575.98px)
    .screen-about
        .content
            font-size: 18px

            .text
                max-width: 80%
